<template>
  <div class="price-container" id="price">
    <h1 class="title">{{ content.title }}</h1>
    <p class="des">{{ content.des }}</p>
    <div class="price-list">
      <div
        class="price-setup"
        v-for="(item, idx) in content.priceSetup"
        :key="idx"
      >
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div v-html="item.price"></div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <ul>
          <li class="des" v-for="line in item.desList" :key="line">
            {{ line }}
          </li>
        </ul>
        <button class="pay-button" @click="nopay">{{ item.buttonTxt }}</button>
        <p class="tip">{{ item.tip }}</p>
      </div>
    </div>
    <div class="toast-warp" v-if="showToast">
      <div class="toast-box">
        <div class="toast-tips">
          We are improving the construction of the website, and if this
          procedure is required, please obtain it by contacting us
        </div>
        <div class="btn-arr">
          <span class="btn" @click="showToast = false">cancel</span>
          <a class="btn sure" href="/tools/contactUs" @click="showToast = false">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showToast: false,
    };
  },
  methods: {
    nopay() {
      this.showToast = true;
    },
  },
};
</script>

<style scoped lang="scss">
.toast-warp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  text-align: center;
  display: flex;
  // align-items: center;
  justify-content: center;
  .toast-box {
    height: 100px;
    justify-content: center;
    width: 600px;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    margin-top: 20vh;
    .toast-tips{
      font-weight: bold;
      font-size: 20px;
    }
  }
  .btn-arr {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    .btn {
      cursor: pointer;
      padding: 8px 20px;
      border-radius: 4px;
      display: flex;
      justify-items: center;
      align-items: center;
      border: 1px solid #32b4ff;
      &.sure {
        background: var(--theme-primary);
        border: none;
        color: #fff;
      }
    }
  }
}
@media (min-width: 981px) {
  .price-container {
    width: 1020px;
    padding: 100px 50px 100px 50px;
    text-align: center;
    margin: 0 auto;
    .title {
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      text-align: center;
      margin-bottom: 24px;
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 44px;
    }
    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    &::v-deep .medium-bold {
      font-size: 14px;
      color: #7c7c7c;
      line-height: 18px;
      margin-top: 10px;
    }
    .price-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      text-align: left;
      .price-setup {
        min-width: 500px;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;
        margin: 0 auto;
        .tag {
          font-size: 14px;
          font-weight: 400;
          color: #76c225;
          line-height: 20px;
          padding: 2px 10px;
          background: #f2f8e9;
          border-radius: 12px;
          margin-left: 8px;
        }
        ul {
          margin-top: 16px;
          margin-bottom: 24px;
          li {
            margin-bottom: 8px;
          }
        }
        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          cursor: pointer;
          background: var(--theme-btn-color);
          border-radius: 30px;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          color: #ffffff;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .tip {
          font-size: 14px;
          color: #7c7c7c;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }
}
@media (max-width: 981px) {
  .price-container {
    width: 100%;
    margin: 1rem auto;
    text-align: center;
    padding: 0.32rem;
    box-sizing: border-box;
    .title {
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      text-align: center;
      margin-bottom: 24px;
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 44px;
    }
    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    &::v-deep .medium-bold {
      font-size: 14px;
      color: #7c7c7c;
      line-height: 18px;
      margin-top: 10px;
    }
    .price-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      text-align: left;
      .price-setup {
        min-width: 100%;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;
        margin: 0 auto;
        .tag {
          font-size: 14px;
          font-weight: 400;
          color: #76c225;
          line-height: 20px;
          padding: 2px 10px;
          background: #f2f8e9;
          border-radius: 12px;
          margin-left: 8px;
        }
        ul {
          margin-top: 16px;
          margin-bottom: 24px;
          li {
            margin-bottom: 8px;
          }
        }
        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          background: #4083fc;
          border-radius: 30px;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          color: #ffffff;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .tip {
          font-size: 14px;
          color: #7c7c7c;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
