import { render, staticRenderFns } from "./Discuss.vue?vue&type=template&id=77a38aaa&scoped=true"
import script from "./Discuss.vue?vue&type=script&lang=js"
export * from "./Discuss.vue?vue&type=script&lang=js"
import style0 from "./Discuss.vue?vue&type=style&index=0&id=77a38aaa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a38aaa",
  null
  
)

export default component.exports